// src/pages/intranet/dashboard.js

import React, { useEffect, useState } from "react";
import TranslateComponent from "../../components/TranslateComponent";
import TranslationBanner from "../../components/TranslationBanner";
import withAuth from "../../components/withAuth";

import HubHeroIntranet from "../../components/HubHeroIntranet";
import IntranetLayout from "../../components/intranet-layout";
import CardLayout2Intranet from "../../components/intranetLayouts/CardLayout2Intranet";
import CardLayout3Intranet from "../../components/intranetLayouts/CardLayout3Intranet";
import CtaIntranet from "../../components/intranetLayouts/CtaIntranet";
import Layout10Intranet from "../../components/intranetLayouts/Layout10Intranet";
import Layout11Intranet from "../../components/intranetLayouts/Layout11Intranet";
import Layout12Intranet from "../../components/intranetLayouts/Layout12Intranet";
import Layout13Intranet from "../../components/intranetLayouts/Layout13Intranet";
import Layout1Intranet from "../../components/intranetLayouts/Layout1Intranet";
import Layout1IntranetVariation from "../../components/intranetLayouts/Layout1IntranetVariation";
import Layout2Intranet from "../../components/intranetLayouts/Layout2Intranet";
import Layout3Intranet from "../../components/intranetLayouts/Layout3Intranet";
import Layout4Intranet from "../../components/intranetLayouts/Layout4Intranet";
import Layout5Intranet from "../../components/intranetLayouts/Layout5Intranet";
import Layout6Intranet from "../../components/intranetLayouts/Layout6Intranet";
import Layout7Intranet from "../../components/intranetLayouts/Layout7Intranet";
import Layout8Intranet from "../../components/intranetLayouts/Layout8Intranet";
import Layout9Intranet from "../../components/intranetLayouts/Layout9Intranet";
import { SafeHtmlParser } from "../../components/SafeHtmlParser";
import SplashScreen from "../../components/SplashScreen";

const componentMap = {
	layout_1_intranet: Layout1Intranet,
	layout_1_intranet_variation: Layout1IntranetVariation,
	layout_2_intranet: Layout2Intranet,
	layout_3_intranet: Layout3Intranet,
	layout_4_intranet: Layout4Intranet,
	layout_5_intranet: Layout5Intranet,
	layout_6_intranet: Layout6Intranet,
	layout_7_intranet: Layout7Intranet,
	layout_8_intranet: Layout8Intranet,
	layout_9_intranet: Layout9Intranet,
	layout_10_intranet: Layout10Intranet,
	layout_11_intranet: Layout11Intranet,
	layout_12_intranet: Layout12Intranet,
	layout_13_intranet: Layout13Intranet,
	card_layout_2_intranet: CardLayout2Intranet,
	card_layout_3_intranet: CardLayout3Intranet,
	cta_intranet: CtaIntranet,
};

const IntranetTimegate = () => {
	const [intranetData, setIntranetData] = useState(null);
	const [languageCode, setLanguageCode] = useState("en");
	const [heroData, setHeroData] = useState({
		heading: "",
		body: "",
		image: "",
	});

	const handleLanguageChange = (code) => {
		setLanguageCode(code);
	};

	const fetchData = async (endpoint) => {
		const token = localStorage.getItem("token");
		try {
			const response = await fetch("/.netlify/functions/fetchData", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ endpoint, token }),
			});
			const data = await response.json();
			if (!response.ok) {
				console.error(`Error fetching ${endpoint} data:`, data.message);
				return null;
			}
			return data;
		} catch (error) {
			console.error("Fetch error:", error);
			return null;
		}
	};

	useEffect(() => {
		const getData = async () => {
			const data = await fetchData("intranet?acf_format=standard");
			if (data) {
				setIntranetData(data);
				const selectedPage = data.find(
					(page) => page.title?.rendered === "Timegate"
				);
				if (selectedPage) {
					const heroSection = selectedPage?.acf?.hero_section;
					setHeroData({
						heading: heroSection?.heading || "",
						body: heroSection?.body || "",
						image: heroSection?.image?.url || "",
					});
				}
			} else {
				setIntranetData(null);
			}
		};
		getData();
	}, []);

	const { heading, body, image } = heroData;

	const newBody = TranslateComponent({ code: languageCode, text: body });
	const newHeading = TranslateComponent({
		code: languageCode,
		text: heading,
	});

	const selectedPage = intranetData?.find(
		(page) => page.title?.rendered === "Timegate"
	);
	const detailsPage = intranetData?.find(
		(page) => page.title?.rendered === "Details"
	);
	const intranetComponents = selectedPage?.acf?.intranet_components || [];
	console.log(intranetComponents);
	return (
		<div>
			{intranetData && selectedPage ? (
				<IntranetLayout
					navLinks={detailsPage?.acf?.navbar_links}
					languageCode={languageCode}
				>
					<div>
						<div className="d-md-none">
							<TranslationBanner
								languageCode={languageCode}
								onSelectLanguage={handleLanguageChange}
							/>
						</div>
						<HubHeroIntranet
							heading={<SafeHtmlParser htmlContent={newHeading} />}
							body={<SafeHtmlParser htmlContent={newBody} />}
							image={image}
						/>
						<div className="d-none d-md-block">
							<TranslationBanner
								languageCode={languageCode}
								onSelectLanguage={handleLanguageChange}
							/>
						</div>
						<div className="py-lg-5">
							{intranetComponents.map((componentData, index) => {
								const LayoutComponent =
									componentMap[componentData.acf_fc_layout];
								if (!LayoutComponent) return null;
								return (
									<LayoutComponent
										key={index}
										languageCode={languageCode}
										{...componentData}
									/>
								);
							})}
						</div>
					</div>
				</IntranetLayout>
			) : (
				<SplashScreen />
			)}
		</div>
	);
};

export default withAuth(IntranetTimegate, "intranet");
